export const removeTrailingZeros = (formattedNumber: string): string => {
    return formattedNumber.endsWith('.00') ? formattedNumber.slice(0, -3) : formattedNumber;
}

export const formatNumber = (
    number: number | undefined,
    minimumFractionDigits: number = 0,
    formatNormal: boolean = false // New parameter
): string => {
    // Handle the case where the input number is undefined
    if (number === undefined) return "0";

    let formattedNumber: number = number;

    // Define thresholds for millions, billions, and trillions
    const suffixes: { value: number, symbol: string }[] = [
        { value: 1e12, symbol: "T" }, // Trillions
        { value: 1e9, symbol: "B" },  // Billions
        { value: 1e6, symbol: "M" },  // Millions
    ];

    if (formatNormal) {
        return Intl.NumberFormat('en-US', {
            minimumFractionDigits: minimumFractionDigits,
        }).format(number);
    }

    // Check if number is larger than any threshold and format accordingly
    for (let i = 0; i < suffixes.length; i++) {
        const suffix = suffixes[i]!;
        if (number >= suffix.value) {
            formattedNumber = number / suffix.value;
            return Intl.NumberFormat('en-US', {
                minimumFractionDigits: minimumFractionDigits,
                maximumFractionDigits: 1, // Limit to 1 decimal place
            }).format(formattedNumber) + suffix.symbol;
        }
    }

    // If the number is less than 1 million, format normally
    return Intl.NumberFormat('en-US', {
        minimumFractionDigits: minimumFractionDigits,
    }).format(number);
}

export const formatMoney = (number: number, currency = 'USD', minimumFractionDigits = 2) => {
    return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: 2
    }).format(number)
}

export const slugToTitle = (slug: string) => {
    return slug
        .split('-')  // Split the slug into words by dashes
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize each word
        .join(' ');  // Join the words back into a single string
}

export const getCountryName = (countryCode: string) => {
    try {
        return new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode)
    } catch (e) {
        return 'N/A (' + countryCode + ')'
    }
}